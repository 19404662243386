(function ($) {
  $(document).ready(function() {
    /*$( "div.spp_product_status div.temp_out_of_stock_msg a.btn-large" ).html('補貨中');
    $( "div.spp_product_status div.coming_soon_stock_msg a.btn-large" ).html('即將上市');
    $( "div.spp_product_status div.inactive_stock_msg" ).html('停產');
    $( "div.spp_product_status div.sold_out_stock_msg" ).html('已售完');
    $( "div.mpp-button-holder a.btn-shopnow" ).html('了解詳情');
    if($('.mpp-wrapper ul.breadcrumbs li').length) {
      $('.mpp-wrapper ul.breadcrumbs li').first().html($('.mpp-wrapper ul.breadcrumbs li').first().html().replace('首页','首頁'));
    }

    var extend_mpp = {
      sortByRated  : function(a,b) {
        $('#sort-type').html(rb.endeca.sorting_p_average_rating).parent().removeClass('hidden');
        return $(a).attr('data-rating') >= $(b).attr('data-rating') ? -1 : 1;
      },
      sortByCollection  : function(a,b) {
        $('#sort-type').html(rb.endeca.dimension_collection).parent().removeClass('hidden');
        return $(a).attr('data-collection') >= $(b).attr('data-collection') ? 1 : -1;
      },
    };*/
    var extend_mpp;
    $.extend(site.mpp,extend_mpp);

  });
})(jQuery);
